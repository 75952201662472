import {Link} from "gatsby";
import style from "../styles/BlogPost.module.scss"
import React from "react"

const BlogPost = ({uid, title, date, subtitle, excerpt, langKey}) => {
	return (
		<Link to={`/${langKey}/blog/${uid}`} aria-label={title}>
			<article className={style.blog}>
				<div className={style.articleheader}>
					<h3 className={style.title}>{title}</h3>
					<p className={style.date}>{date}</p>
				</div>
				<p className={style.subtitle}>{subtitle}</p>
				<p className={style.excerpt}>{excerpt}</p>
				{/* DER FOLGENDE TEXT MUSS ZUR LOKALISIERUNG ÜBERSETZT WERDEN */}
				<span className={style.readmore}>Read More
					<span className={style.arrows}>
						<svg className={style.svg} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
							<line x1="5" y1="12" x2="19" y2="12" />
							<line x1="15" y1="16" x2="19" y2="12" />
							<line x1="15" y1="8" x2="19" y2="12" />
						</svg>
					</span>
				</span>
			</article>
		</Link>
	);
}
 
export default BlogPost;