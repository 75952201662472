import React, { useState } from 'react';
import styles from "../styles/Home.module.scss"
import workStyle from "../styles/Work.module.scss"
import {Link} from "gatsby"
import MetaHead from "../components/MetaHead"
import WorkPost from "../components/WorkPost"
import BlogPost from "../components/BlogPost"
import Layout from "../components/Layout"
import {graphql} from "gatsby"

const Page = ({data, pageContext, path}) => {
  	const home = data.contentfulHome
	const essentialData = data.contentfulEssentialData
	return(
		<>
			<MetaHead meta={data.contentfulMetaFields} lang={pageContext.langKey} path={path} />
			<Layout essentialData={{...essentialData, ...pageContext, path}}>
				<p className={styles.head}>
					<span className={styles.variable}>h</span>
					<span className={styles.variable}>e</span>
					<span className={styles.variable}>y </span>
					<span className={styles.variable}>h</span>
					<span className={styles.variable}>e</span>
					<span className={styles.variable}>y</span>
					<span className={styles.variable}>!</span>
				</p>

				<div className={styles.sub_head}><p>{home.subHeaderText.subHeaderText}</p></div>
				
				<a className={styles.cta_main} href="mailto:email@ericwaetke.com">{home.workTogether?.workTogether}</a>

				<h2>{home.workHeader}</h2>
				<div className={workStyle.work_wrapper}>
					{
						home.workPosts.map((work_post) => {
							return (
								<WorkPost 
								key={work_post.slug}
								uid={work_post.slug}
								lang={pageContext.langKey}
								title={work_post.title}
								subtitle={work_post.subtitle.subtitle}
								image={work_post.workThumbnail}
								langKey={pageContext.langKey}
								/>
							)
						})
					}
				</div>

				
				

				<h2 className={styles.recent}>{home.blogHeader}</h2>
				<div>
					{
						data.allContentfulBlogPost.nodes.map((blog_post) => {
							return (
								<BlogPost 
								key={blog_post.slug}
								uid={blog_post.slug}
								lang={pageContext.langKey}
								title={blog_post.title}
								date={blog_post.date}
								subtitle={blog_post.subtitle.subtitle}
								excerpt={blog_post.excerpt.excerpt}
								langKey={pageContext.langKey}
								/>
							)
						})
					}
				</div>
				<Link to={`/${pageContext.langKey}/blog/`} className={styles.cta}>{home.allBlogPosts}</Link>
			</Layout>
		</>
	)
}

export const query = graphql`
query($langKey: String!){
	contentfulHome(node_locale: {eq: $langKey}) {
		allBlogPosts
		workHeader
		workPosts {
			title
			workThumbnail {
				fluid {
					...GatsbyContentfulFluid
				}
			}
			slug
			subtitle {
				subtitle
			}
		}
		subHeaderText {
			subHeaderText
		}
		blogHeader
		workTogether {
			workTogether
		}
	}
	allContentfulBlogPost(filter: {node_locale: {eq: $langKey}}) {
		nodes {
			title
			subtitle {
				subtitle
			}
			slug
			excerpt {
				excerpt
			}
			date(formatString: "DD.MM.YYYY")
		}
	}
	contentfulEssentialData(node_locale: {eq: $langKey}) {
		workTitle
		resourcesTitle
		footerCopyright
		footerContact
		blogTitle
		aboutTitle
		homeTitle
		imprintTitle
	}
	contentfulMetaFields(identifier: {eq: "home"}, node_locale: {eq: $langKey}) {
		title
		ogType
		description {
			description
		}
	}
}
`


export default Page