import React from "react"
import {Link} from "gatsby";
import style from "../styles/WorkPost.module.scss"
import Img from "gatsby-image"

const WorkPost = ({uid, title, subtitle, image, langKey}) => {
	return (
		<Link to={`/${langKey}/blog/${uid}`} className={style.work} aria-label={title}>
			<article >
				<Img 
				alt={title}
				fluid={image.fluid}
				/>
				<h3 className={style.h3}>{title}</h3>
				<p className={style.p}>{subtitle}</p>
			</article>
		</Link>
	);
}

export default WorkPost;